<template>
  <div class="box" v-loading.fullscreen.lock="loading">
    <img
      class="m_tb_20 pointer"
      :src="banner"
      alt=""
      width="100%"
      @click="toUploadWorks_click"
    />
    <el-breadcrumb class="m_b_10" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{
          name: 'regionResource',
          params: { classify: '1555104854509219841', name: '劳动教育' },
        }"
      >
        劳动教育
      </el-breadcrumb-item>
      <el-breadcrumb-item>劳动基地</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="p_in_20 bg_fff br_5 m_b_20">
      <div class="flex_row m_b_10" v-for="item in resourceList" :key="item.id">
        <img
          :src="item.coverUrl"
          alt=""
          class="m_r_20 br_5"
          style="width: 230px; height: 120px; object-fit: cover; flex-shrink: 0"
        />
        <div class="flex_col" style="width: 100%">
          <div class="fs_18 m_b_10">{{ item.name }}</div>
          <div class="fs_14 c_666 m_b_10" v-html="item.introduce"></div>
          <button class="btn pointer" @click="toDetail_click(item)">
            查看详情
          </button>
        </div>
      </div>
    </div>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      hide-on-single-page
      :current-page.sync="params.current"
      @current-change="current_change"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import banner from "assets/image/Group 640.png";
import course from "assets/image/Group 651.png";
import { getResourceList, getPhoneEncrypt } from "@/api/resourceCenter";
import { sessionGet } from "utils/local";

export default {
  name: "Base",
  data() {
    return {
      banner,
      course,
      params: {
        contentsId: "1603289314656067585",
        current: 1,
        size: 10,
      },
      total: 0,
      resourceList: [],
      loading: false,
    };
  },
  mounted() {
    this.getResource();
  },
  methods: {
    async getResource() {
      this.loading = true;
      await getResourceList(this.params).then((res) => {
        this.resourceList = res.data.records;
        this.total = res.data.total;
      });
      this.loading = false;
    },
    current_change(current) {
      this.params.current = current;
      this.getResource();
    },
    toDetail_click(data) {
      window.open(data.resourcesUrl, "_blank");
    },
    toUploadWorks_click() {
      let userInfo = sessionGet("userInfo");
      if (!userInfo) return this.$message.warning("请登录");
      getPhoneEncrypt({ id: userInfo.user_id }).then((res) => {
        if (res.code !== 200) return this.$message.error(res.msg);
        window.open(
          `https://cdwh.wuhousmartedu.com/portalJump/toActivityGuide/${res.data}`
        );
      });
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 0 auto;
}

.btn {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #12c294;
  border-radius: 4px;
  font-weight: 500;
  color: #12c294;
  line-height: 16px;
  align-self: flex-end;
}
</style>
